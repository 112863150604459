body {
  font-family: 'Calibri', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0px;
  color: #17202A;
  height: 100%;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
