.header {
    background-color: white;
    height: 96px;
    width: 100%;
    display: grid;
    grid-template-columns: 250px auto auto;
    grid-template-rows: 96px;
    grid-column-gap: 5px;
    position: sticky;
    top: 0;
    box-shadow: 0 -5px 5px -3px rgb(0 0 0 / 20%), 
                0 -8px 10px 1px rgb(0 0 0 / 14%), 
                0 -3px 14px 2px rgb(0 0 0 / 12%);
    z-index: 5;
}

.chn-logo {
    height: 96px;
}

.tabs {
    margin-top:40px;
}

.tabs > div {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 18px 36px;
    cursor: pointer;
}

.tab-selected {
    color: #01A0C0;
    border-bottom: 2px solid;
    border-image: linear-gradient(to right, #006bdf 0%, #006bdf 25%, #01a0bd 25%, #01a0bd 50%, #9c9c9c 50%, #9c9c9c 75%, #485074 75%, #485074 100%);
    border-image-slice: 2;
    border-image-width: 0px 0px 2px 0px;
    border-image-repeat: round;
}
  
.tab-selected > span {
    text-decoration: none;
    display: block;
    text-align: center;
    position: relative;
    color: #485074;
    font-size: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: none;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.tab-selected > span:hover {
    color: #01A0C0;
}

.tab-selected > span:hover {
    color: #485074;
}