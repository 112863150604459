button {
  border: none;
  border-radius: 2px;
  background: rgb(72,80,116);
  color: white;
  padding: 6px 22px;
  letter-spacing: .8px;
  margin: 15px 0px;
  margin-right: 15px;
  cursor: pointer;
  min-width: 200px;
}
  
button:disabled {
  background-color: gray;
}

.files {
  width: 100%;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
}

.files > span {
  width: 100%;
  margin: 5px;
  font-family: monospace;
}